import React, { memo, useMemo } from "react"
import { ResultsProps } from "@usereactify/search"

import { useShopify } from "@app/hooks/useShopify"
import { ProductCard } from "@app/components/Product/Card/ProductCard"

type Props = React.ComponentProps<NonNullable<ResultsProps["renderResultCardProduct"]>>

export const SearchFormResultsListItem: React.FC<Props> = memo(({ pagePosition, product }) => {
  const { adminProductNormaliser } = useShopify()

  const item = useMemo(() => adminProductNormaliser(product), [adminProductNormaliser, product])

  return <ProductCard item={item} pagePosition={pagePosition} />
})
