import { useCallback, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMutation } from "@apollo/client"
import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useQueries } from "@app/hooks/useQueries"
import { useCartContext } from "@app/providers/cart"
import { useAppContext } from "@app/providers/app"
import { useCore } from "@app/hooks/useCore"
import { AttributeProps } from "@root/types/global"
import { CartUserError, CartLine, Attribute, AttributeInput } from "@shopify/hydrogen-react/storefront-api-types"

type CartItemPayload = {
  attributes?: Array<AttributeInput>
  quantity?: number
  variantId: string
}

type FreeShippingResponse = {
  message?: string
  percentage?: number
  threshold?: number
  total?: number
}

type UseCart = {
  errors: Array<CartUserError>
  freeShipping: FreeShippingResponse
  loading: boolean
  unavailableItems: Array<CartLine>
  addToCart: (variantId: string, quantity?: number, attributes?: Array<AttributeInput>, drawer?: boolean) => Promise<void | null>
  addToCartMultiple: (items: Array<CartItemPayload>) => Promise<void | null>
  clearCart: () => Promise<void | null>
  removeFromCart: (id: string, variantId: string) => Promise<void | null>
  updateQuantity: (id: string, variantId: string, quantity: number, action?: string) => Promise<void | null>
  updateLineItemAttribute: (lines: Array<CartLine>) => Promise<void | null>
  updateEmail: (email: string) => Promise<void | null>
  removeOosFromCart: () => Promise<void | null>
}
export const useCart = (): UseCart => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const {
    graphql: {
      mutations: { CART_LINE_ADD, CART_LINE_UPDATE, CART_LINE_REMOVE, CART_BUYER_IDENTITY_UPDATE },
    },
  } = useQueries()
  const { cart, id: cartId, saveCart, countryCode, setLoading: setCartLoading, refreshCartStorage, setWarnings } = useCartContext()
  const { cartNormaliser, formatMoney } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { dispatch } = useAppContext()

  const [errors, setErrors] = useState<Array<CartUserError>>([])
  const [loading, setItemLoading] = useState(false)
  const [cartLinesAdd] = useMutation(CART_LINE_ADD)
  const [cartLinesUpdate] = useMutation(CART_LINE_UPDATE)
  const [cartLinesRemove] = useMutation(CART_LINE_REMOVE)
  const [cartBuyerIdentityUpdate] = useMutation(CART_BUYER_IDENTITY_UPDATE)

  const setLoading = useCallback(
    (loading: boolean) => {
      // Update local hook loading state
      setItemLoading(loading)
      // Update global provider loading state
      setCartLoading(loading)
    },
    [setCartLoading]
  )

  const prepareAttributes = useCallback(
    (attributes: Array<AttributeProps>) =>
      attributes?.map(({ key, value }) => ({
        key,
        value,
      })) || [],
    []
  )

  const addToCart = useCallback(
    async (variantId: string, quantity = 1, attributes: Array<AttributeInput> = [], drawer = true, newCart = ""): Promise<void | null> => {
      if (!cartId) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)

      // Remove empty attributes if any, this causes an error in the cart
      attributes = attributes?.filter(({ value }) => !!value)

      const lines = {
        attributes: [...(prepareAttributes(attributes) || []), ...(attributes || [])],
        quantity: quantity,
        merchandiseId: variantId,
      }

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          cartId: newCart.length ? newCart : cartId,
          lines,
        },
      })

      if (data?.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.warnings?.length) {
        setWarnings(data.warnings)
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)
        refreshCartStorage(normalisedCart)
        trackCartUpdate(
          "add",
          `gid://shopify/ProductVariant/${decodeShopifyId(variantId, "ProductVariant")}`,
          quantity,
          normalisedCart?.lines
        )
      }

      if (drawer) dispatch({ type: "cart", payload: true })

      setLoading(false)
    },
    [
      cartId,
      prepareAttributes,
      cartLinesAdd,
      cartNormaliser,
      dispatch,
      trackCartUpdate,
      decodeShopifyId,
      saveCart,
      setLoading,
      setWarnings,
      refreshCartStorage,
    ]
  )

  const addToCartMultiple = useCallback(
    async (items: Array<CartItemPayload>): Promise<void | null> => {
      if (!cartId) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)

      const lines =
        items?.map(line => ({
          attributes: (line?.attributes as Attribute[])
            ?.filter(({ value }) => !!value)
            ?.map(({ key, value }) => ({
              key,
              value,
            })),
          quantity: line?.quantity,
          merchandiseId: line?.variantId,
        })) || []

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          countryCode,
          cartId,
          lines,
        },
      })

      if (data?.userErrors?.length) {
        setErrors(data?.userErrors)
        setLoading(false)
      } else {
        setErrors([])
      }

      if (data?.warnings?.length) {
        setWarnings(data.warnings)
      } else {
        setWarnings([])
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)
        refreshCartStorage(normalisedCart)
        for (const item of items) {
          trackCartUpdate("add", item.variantId, item.quantity, cartNormaliser(data?.cart)?.lines)
        }
      }
      dispatch({ type: "cart", payload: true })
      setLoading(false)
    },
    [cartLinesAdd, countryCode, cartId, saveCart, dispatch, trackCartUpdate, cartNormaliser, setLoading, setWarnings, refreshCartStorage]
  )

  const removeFromCart = useCallback(
    async (id: string, variantId: string) => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }

      setLoading(true)
      const quantity = cart?.lines.filter(line => line.id === id).map(({ quantity }) => quantity)[0] || 1

      const lineIds = cart?.lines.filter(line => line.id === id).map(line => line.id)

      const {
        data: { cartLinesRemove: data },
      } = await cartLinesRemove({
        variables: { cartId, lineIds },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      } else {
        setErrors([])
      }

      if (data?.warnings?.length) {
        setWarnings(data.warnings)
      } else {
        setWarnings([])
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)
        refreshCartStorage(normalisedCart)
        trackCartUpdate("remove", variantId, quantity, cart?.lines)
      }

      setLoading(false)
    },
    [cartId, cart, setLoading, setWarnings, cartLinesRemove, cartNormaliser, saveCart, refreshCartStorage, trackCartUpdate]
  )

  const removeOosFromCart = useCallback(async () => {
    if (!cartId || !cart) {
      console.error("Cart ID not found")
      return
    }

    setLoading(true)
    const lineIds = cart?.lines.filter(line => !line?.merchandise?.availableForSale).map(line => line.id)

    const {
      data: { cartLinesRemove: data },
    } = await cartLinesRemove({
      variables: { cartId, lineIds },
    })

    if (data.userErrors?.length) {
      setErrors(data.userErrors)
      setLoading(false)
    } else {
      setErrors([])
    }

    if (data?.warnings?.length) {
      setWarnings(data.warnings)
    } else {
      setWarnings([])
    }

    if (data?.cart) {
      const normalisedCart = cartNormaliser(data.cart)
      saveCart(data.cart)
      refreshCartStorage(normalisedCart)
    }

    setLoading(false)
  }, [cartId, cart, setLoading, cartLinesRemove, cartNormaliser, saveCart, refreshCartStorage, setWarnings])

  const updateQuantity = useCallback(
    async (id: string, variantId: string, quantity: number, action?: string): Promise<void | null> => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }

      setLoading(true)
      const lines = cart?.lines
        .filter(line => line.id === id)
        .map(line => ({
          id: line.id,
          quantity: quantity,
          merchandiseId: line?.merchandise?.id,
        }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      } else {
        setErrors([])
      }

      if (data?.warnings?.length) {
        setWarnings(data.warnings)
      } else {
        setWarnings([])
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)
        refreshCartStorage(normalisedCart)
        trackCartUpdate(action, variantId, quantity, normalisedCart?.lines)
      }

      setLoading(false)
    },
    [cartId, cart, setLoading, setWarnings, cartLinesUpdate, cartNormaliser, saveCart, refreshCartStorage, trackCartUpdate]
  )

  const updateLineItemAttribute = useCallback(
    async (lines: Array<CartLine>): Promise<void | null> => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)
      const cartLines = lines?.map(({ id, quantity, merchandise, attributes }) => ({
        id,
        quantity,
        merchandiseId: merchandise?.id,
        attributes: attributes?.map(({ key, value }) => ({ key, value })),
      }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines: cartLines },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      } else {
        setErrors([])
      }

      if (data?.warnings?.length) {
        setWarnings(data.warnings)
      } else {
        setWarnings([])
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        saveCart(data.cart)
        refreshCartStorage(normalisedCart)
      }

      setLoading(false)
    },
    [cartId, cart, setLoading, setWarnings, cartLinesUpdate, cartNormaliser, saveCart, refreshCartStorage]
  )

  const updateEmail = useCallback(
    async (email: string) => {
      const {
        data: {
          cartBuyerIdentityUpdate: { cart },
        },
      } = await cartBuyerIdentityUpdate({
        variables: { cartId, buyerIdentity: { email } },
      })
      saveCart(cart)
    },
    [cartBuyerIdentityUpdate, saveCart, cartId]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)
    setErrors([])
    setWarnings([])

    cart?.lines?.map(({ merchandise, quantity }: { merchandise: any; quantity: number }) =>
      trackCartUpdate("remove", merchandise?.id, quantity, cart?.lines)
    )

    const {
      data: { cartLinesRemove: data },
    } = await cartLinesRemove({
      variables: { cartId, lines: [] },
    })

    if (data.userErrors?.length) {
      setErrors(data.userErrors)
      setLoading(false)
    } else {
      setErrors([])
    }

    if (data?.warnings?.length) {
      setWarnings(data.warnings)
    } else {
      setWarnings([])
    }

    if (data?.cart) {
      const normalisedCart = cartNormaliser(data.cart)
      saveCart(data.cart)
      refreshCartStorage(normalisedCart)
    }

    setLoading(false)
  }, [cart?.lines, cartLinesRemove, cartId, trackCartUpdate, saveCart, setLoading, setWarnings, refreshCartStorage, cartNormaliser])

  const { shipping } = useStaticQuery<GatsbyTypes.StaticShippingQuery>(graphql`
    query StaticShipping {
      shipping: sanitySettingShipping {
        threshold
        includeDiscounts
        additionalBefore
        additionalAfter
      }
    }
  `)

  const freeShipping = useMemo(() => {
    const threshold = shipping?.threshold ?? 100
    const includeDiscounts = shipping?.includeDiscounts ?? true
    const total = includeDiscounts ? Number(cart?.cost?.subtotalAmount?.amount) : Number(cart?.cost?.totalAmount?.amount)
    const percentage = total > threshold ? 100 : (total / threshold) * 100
    const remaining = total > threshold ? 0 : threshold - total
    const rawMessage = percentage === 100 ? shipping?.additionalAfter : shipping?.additionalBefore
    const message = rawMessage?.replace("{threshold}", formatMoney(threshold))?.replace("{remaining}", formatMoney(remaining))
    return { threshold, percentage, message }
  }, [shipping, cart?.cost?.totalAmount?.amount, cart?.cost?.subtotalAmount?.amount, formatMoney])

  const unavailableItems = useMemo(() => cart?.lines?.filter(line => !line?.merchandise?.availableForSale) || [], [cart?.lines])

  return {
    addToCart,
    addToCartMultiple,
    removeFromCart,
    updateQuantity,
    updateLineItemAttribute,
    clearCart,
    freeShipping,
    loading,
    errors,
    updateEmail,
    unavailableItems,
    removeOosFromCart,
  }
}
