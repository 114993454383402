import React, { memo, useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"
import { Icon } from "@app/components/Icon"

export const CartShipping: React.FC = memo(() => {
  const {
    freeShipping: { percentage: freeShippingPercentage, message: freeShippingMessage },
  } = useCart()
  const { gwpPercentage, gwpSettings } = useGiftWithPurchase()

  const content = useMemo(() => {
    return gwpSettings?.giftWithPurchaseEnabled
      ? {
          message: freeShippingMessage,
          percentage: gwpPercentage,
        }
      : {
          message: freeShippingMessage,
          percentage: freeShippingPercentage,
        }
  }, [gwpSettings?.giftWithPurchaseEnabled, freeShippingMessage, freeShippingPercentage, gwpPercentage])

  const thresholdMet = useMemo(() => freeShippingPercentage && freeShippingPercentage >= 100, [freeShippingPercentage])

  return (
    <Box
      mb={2}
      px={4}
      py={3}
      pos="relative"
      _before={{
        content: "''",
        bgColor: thresholdMet ? "brand.sky" : "grey.cloud",
        pos: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: thresholdMet ? 0.5 : 1,
        transition: "0.2s background-color",
      }}
    >
      <Flex gap={2} alignItems="center" pos="relative">
        <Flex w="20px" h="20px" alignItems="center" justifyContent="center">
          <Icon name={thresholdMet ? "tick" : "truckFreeShipping"} width="14px" fill="none" />
        </Flex>
        <Text size="xs" flex={1} color="grey.darkest" py={1}>
          {content.message}
        </Text>
      </Flex>
      <Box borderRadius={2} h="2px" mt={2} pos="relative" overflow="hidden">
        <Box bgColor="#aaaaaa" w="100%" h="100%" pos="absolute" />
        <Box
          bgColor="grey.darkest"
          borderRightRadius={thresholdMet ? 0 : 2}
          w={`${content.percentage}%`}
          h="100%"
          pos="absolute"
          transition="0.2s width, 0.2s background-color"
        />
      </Box>
    </Box>
  )
})
