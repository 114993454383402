import { Radio as BaseRadio } from "@chakra-ui/react"

export const Radio = {
  ...BaseRadio.defaultProps,
  baseStyle: {
    ...BaseRadio.baseStyle,
    control: {
      ...BaseRadio.baseStyle?.control,
      bg: "none",
      borderColor: "grey.darkest",
      _before: {
        height: "80% !important",
        width: "80% !important",
      },
      _checked: {
        bg: "white",
        borderColor: "grey.darkest",
        color: "black",
      },
    },
  },
}
