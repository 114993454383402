import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react"

import config from "@root/config.js"

import { Link } from "@app/components/Link"
import { graphql, useStaticQuery } from "gatsby"
import { useCartContext } from "@app/providers/cart"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useCart } from "@app/hooks/useCart"
import { useFunctions } from "@app/hooks/useFunctions"

export const CartAbandonedWidget: React.FC = () => {
  const cartData = useStaticQuery<GatsbyTypes.StaticCartPopupQuery>(graphql`
    query StaticCartPopup {
      cart: sanityPageCart {
        activeCartReminderEnabled
        activeCartReminderTitle
        activeCartReminderSubtitle
        activeCartReminderButtonLabel
        activeCartReminderOosButtonLabel
      }
    }
  `)
  const { cart } = useCartContext()
  const { unavailableItems } = useCart()
  const { callFunction, getFunction } = useFunctions()

  const { trackActiveCartClose, trackActiveCartCheckout } = useAnalytics()

  const [isActive, setIsActive] = useState(false)
  const initialRef = useRef(null)

  const hasAvailable = useMemo(() => cart?.lines?.some(line => line.merchandise.availableForSale), [cart?.lines])
  const displayPopup = useMemo(() => {
    return cart?.lines?.length && hasAvailable && cartData.cart?.activeCartReminderEnabled
  }, [cart?.lines, hasAvailable, cartData.cart?.activeCartReminderEnabled])
  const cartReminderApi = "cart-reminder"
  const isCartInitialised = !!cart

  // handle session storage and popup display
  useEffect(() => {
    ;(async () => {
      if (!isCartInitialised) return

      // Check session cookie to determine if cart reminder popup should be displayed
      const session = await getFunction(cartReminderApi)

      const hideCartReminder = !!session?.hideCartReminder

      if (hideCartReminder) return

      // Display popup if:
      //  - cart contains atleast 1 in-stock item
      //  - cart reminder is enabled (within Sanity)
      if (displayPopup) setIsActive(true)
      // Else, set session cookie to hide cart reminder popup, for subsequent visits within the same browser session
      else callFunction(cartReminderApi, { hideCartReminder: true })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartInitialised])

  const cartContainsSoldoutItems = unavailableItems.length > 0
  const buttonLabel = !cartContainsSoldoutItems
    ? cartData.cart?.activeCartReminderButtonLabel
    : cartData.cart?.activeCartReminderOosButtonLabel
  const buttonUrl = !cartContainsSoldoutItems ? cart?.checkoutUrl : config.settings.routes.CART

  const handleClick = () => {
    if (cart?.lines.length) trackActiveCartCheckout(cart?.lines)
    // Set session cookie to hide cart reminder popup, for subsequent visits within the same browser session
    callFunction(cartReminderApi, { hideCartReminder: true })
    setIsActive(false)
  }

  const handleClose = () => {
    setIsActive(false)
    // Set session cookie to hide cart reminder popup, for subsequent visits within the same browser session
    trackActiveCartClose(cart?.lines)
    callFunction(cartReminderApi, { hideCartReminder: true })
  }

  return (
    <Modal autoFocus={false} initialFocusRef={initialRef} isOpen={isActive} onClose={handleClose} size="sm">
      <ModalOverlay background="rgba(30, 40, 40, 0.3)" backdropFilter="none" />

      <ModalContent
        position="fixed"
        top={["unset", 27]}
        right={[6, 5, 14]}
        bottom={[6, "unset"]}
        left={[6, "unset"]}
        width={["auto", "100%"]}
        maxWidth={["none", "sm"]}
        marginBottom={0}
        padding={8}
        borderRadius={6}
        textAlign="center"
        color="grey.text"
      >
        <ModalHeader paddingBottom={4} fontSize="3xl" fontWeight="medium" lineHeight={1.16}>
          {cartData.cart?.activeCartReminderTitle}
        </ModalHeader>
        <ModalCloseButton width={12} height={12} top={0} right={0} />
        <ModalBody padding={0}>
          <Text lineHeight={1.22} fontWeight="500">
            {cartData.cart?.activeCartReminderSubtitle}
          </Text>
        </ModalBody>
        <ModalFooter padding={0} marginTop={6}>
          <Button
            as={Link}
            to={buttonUrl}
            ref={initialRef}
            width="100%"
            onClick={handleClick}
            background="grey.coal"
            paddingY={4}
            paddingX={6}
            border="none"
            borderRadius={8}
            fontWeight="500"
            color="grey.white"
          >
            {buttonLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
