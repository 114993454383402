import React from "react"

import { Box, Flex, FlexProps, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import { useCart } from "@app/hooks/useCart"
import { Icon } from "@app/components/Icon"

export type Props = {
  isCartPage?: boolean
} & FlexProps

export const CartError: React.FC<Props> = ({ isCartPage = false, ...rest }) => {
  const { unavailableItems } = useCart()
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartErrorQuery>(graphql`
    query StaticCartError {
      cart: sanityPageCart {
        additionalUnavailableMessage
      }
    }
  `)

  return unavailableItems?.length ? (
    <Flex mb={2} py={1} px={4} bg="brand.errorLight" gap={2} borderRadius={"4px"} alignItems="start" {...rest}>
      <Box minWidth={5}>
        <Icon name="info" color="brand.error" width={5} />
      </Box>
      <Text size="sm" color="brand.error" lineHeight="18px" mt="1px">
        {cart?.additionalUnavailableMessage}
      </Text>
    </Flex>
  ) : null
}
