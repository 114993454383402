import { useEffect, useState } from "react"

export enum ScrollDirection {
  up = "up",
  down = "down",
}

export const useScrollDirection = (thresholdInput: number) => {
  const threshold = thresholdInput || 100
  const [scrollDir, setScrollDir] = useState(ScrollDirection.up)

  useEffect(() => {
    let previousScrollYPosition = window.scrollY
    let timeoutId: NodeJS.Timeout | null = null

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold

    const isScrollingUp = (currentScrollYPosition: number) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0)

    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY

      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.down : ScrollDirection.up
        setScrollDir(newScrollDirection)
        previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0
      }
    }

    const debouncedUpdateScrollDirection = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(updateScrollDirection, 200)
    }

    const onScroll = () => window.requestAnimationFrame(debouncedUpdateScrollDirection)

    window.addEventListener("scroll", onScroll)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      window.removeEventListener("scroll", onScroll)
    }
  }, [threshold])

  return scrollDir
}
