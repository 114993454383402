import { AspectRatio, Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

import { FormButton } from "@app/components/Form/FormButton"
import { FormQuantity } from "@app/components/Form/FormQuantity"
import { Link } from "@app/components/Link"
import ProductFormPrice from "@app/components/Product/Form/ProductFormPrice"
import { useCart } from "@app/hooks/useCart"
import { useCore } from "@app/hooks/useCore"
import { useDebounce } from "@app/hooks/useDebounce"
import { useMedia } from "@app/hooks/useMedia"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"

import Discount from "@app/components/Discount"
import { useShopify } from "@app/hooks/useShopify"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type Props = {
  item: CartLine
}

export const CartListItem: React.FC<Props> = memo(({ item }) => {
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartListItemQuery>(graphql`
    query StaticCartListItem {
      cart: sanityPageCart {
        additionalGiftcardSendername
        additionalGiftcardName
        additionalGiftcardEmail
        additionalGiftcardDate
        additionalGiftcardMessage
        additionalPersonalised
        additionalRemove
        additionalColour
        additionalUnavailableLabel
      }
    }
  `)
  const {
    settings: {
      product: { colourPrefix, giftCardType, giftCardSortOrder },
      routes,
      cart: cartConfig,
    },
  } = useConfigContext()
  const { loading, removeFromCart, updateQuantity } = useCart()
  const {
    helpers: { capitalise },
  } = useCore()
  const { isBase } = useMedia()
  const { urlResolver } = useRoutes()
  const [quantity, setQuantity] = useState<number>(Number(item?.quantity))

  const { imageNormaliser } = useShopify()

  const isGwp = useMemo(() => item?.attributes?.some(ca => ca.key === "_is-gwp"), [item?.attributes])
  const isOutOfStock = useMemo(() => !item?.merchandise?.availableForSale, [item?.merchandise?.availableForSale])

  const handleQuantity = useCallback((quantity: number) => setQuantity(quantity), [setQuantity])

  const handleRemove = useCallback(() => removeFromCart(item?.id, item?.merchandise?.id), [item, removeFromCart])

  const discount = useMemo<number>(
    () =>
      item?.discountAllocations?.reduce((total: number, discount) => total + (Number(discount?.discountedAmount?.amount) || 0), 0) /
        item.quantity || 0,
    [item]
  )

  const linePrice = useMemo(() => Number(item?.merchandise?.priceV2?.amount) - discount, [discount, item])
  const lineCompareAtPrice = useMemo(
    () =>
      item?.merchandise?.compareAtPriceV2?.amount
        ? Number(item?.merchandise?.compareAtPriceV2?.amount)
        : Number(item?.merchandise?.priceV2?.amount),
    [item]
  )

  const image = useMemo(() => imageNormaliser(item?.merchandise?.image), [item?.merchandise?.image, imageNormaliser])
  const link = useMemo(
    () => urlResolver(item?.merchandise?.product, routes.PRODUCT),
    [item?.merchandise?.product, routes.PRODUCT, urlResolver]
  )

  const colour = item?.merchandise?.product?.tags
    ?.find(tag => tag?.includes(colourPrefix))
    ?.split(":")?.[1]
    ?.replace(/-/g, " ")
  const giftCardAttributes = useMemo(
    () =>
      item?.attributes
        ?.filter(({ key }) => giftCardSortOrder?.includes(key))
        ?.sort(({ key: keyA }, { key: keyB }) => giftCardSortOrder.indexOf(keyA) - giftCardSortOrder.indexOf(keyB))
        ?.map(({ key, value }) => ({
          key: cart[
            `additionalGiftcard${capitalise(
              key.replace("_gift_recipient_", "").replace("delivery_", "").replace("_gift_sender_name", "sendername")
            )}`
          ],
          value:
            key.includes("date") && value?.split("/")?.length === 3
              ? `${value?.split("/")[1]}.${value?.split("/")[0]}.${value?.split("/")[2]}`
              : value,
        }))
        ?.filter(({ key }) => key !== undefined),
    [capitalise, cart, giftCardSortOrder, item]
  )
  const isGiftcard = useMemo(
    () => item?.merchandise?.product?.productType === giftCardType,
    [giftCardType, item?.merchandise?.product?.productType]
  )

  const isAutoAdd = useMemo(
    () => !!item?.attributes?.find(x => cartConfig?.gwpAutoAdd && x.key === cartConfig.gwpAutoAdd),
    [cartConfig.gwpAutoAdd, item?.attributes]
  )

  useDebounce(
    () => {
      if (quantity !== Number(item.quantity)) {
        const action = quantity > item.quantity ? "add" : "remove"
        quantity ? updateQuantity(item.id, item.merchandise?.id, quantity, action) : removeFromCart(item.id, item.merchandise?.id)
      }
    },
    500,
    [quantity]
  )

  useEffect(() => {
    // State update when the item's quantity changes
    setQuantity(Number(item.quantity))
  }, [item])

  return (
    <HStack alignItems="stretch" justifyContent="space-between" spacing={[6.25, 10]}>
      {/* Workaround to prevent link hover to trigger un-necessary re-rerenders, crashing local dev */}
      <Box w={100} onMouseEnter={e => e.stopPropagation()}>
        <Link title={item?.merchandise?.product.title} aria-label={item?.merchandise?.product.title} to={link?.url}>
          <AspectRatio bg="grey.cloud" ratio={100 / 140}>
            <>{image && item?.merchandise?.product && <Image {...image} alt={image?.alt || item?.merchandise?.product.title} />}</>
          </AspectRatio>
        </Link>
      </Box>
      <Flex flexDirection="column" flex={1} alignItems="stretch" justifyContent="space-between">
        <Flex flexDir="column" flex={1} gap={1}>
          <Flex alignItems="flex-start" justifyContent="space-between" flexWrap={["wrap", "nowrap"]}>
            <Box flex={1}>
              {/* Workaround to prevent link hover to trigger un-necessary re-rerenders, crashing local dev */}
              <div onMouseEnter={e => e.stopPropagation()}>
                <Link title={item?.merchandise?.product.title} aria-label={item?.merchandise?.product.title} to={link?.url}>
                  <Text letterSpacing="generous" size="sm">
                    {item?.merchandise?.product.title}
                  </Text>
                </Link>
              </div>
              {!isBase && !isGwp && <Discount tags={item?.merchandise?.product?.tags} fontSize={14} letterSpacing="generous" />}
            </Box>
            <ProductFormPrice
              variant={{
                ...item?.merchandise,
                ...(discount > 0
                  ? {
                      compareAtPriceV2: { ...item?.merchandise?.priceV2, amount: lineCompareAtPrice },
                      priceV2: { ...item?.merchandise?.priceV2, amount: linePrice },
                    }
                  : {
                      compareAtPriceV2: item?.merchandise?.compareAtPriceV2,
                      priceV2: { ...item?.merchandise?.priceV2 },
                    }),
              }}
              direction={["row", "column-reverse", "column-reverse"]}
              alignItems="flex-end"
              spacing={[2, 0, 0]}
              w={["full", "auto"]}
              isGwp={isGwp}
              quantity={quantity}
            />
            {isBase && <Discount tags={item?.merchandise?.product?.tags} fontSize={12} letterSpacing="generous" />}
          </Flex>
          <VStack alignItems="flex-start" justifyContent="flex-start" spacing={[0, 0.5]}>
            {isOutOfStock && (
              <Text letterSpacing="generous" size="xs" color="brand.error">
                {cart?.additionalUnavailableLabel}
              </Text>
            )}
            {colour && (
              <Text letterSpacing="generous" size="xs" color="grey.lake">
                {cart?.additionalColour?.replace("{value}", capitalise(colour))}
              </Text>
            )}
            {item?.merchandise?.selectedOptions?.length > 0 && !isGiftcard && (
              <>
                {item?.merchandise?.selectedOptions.map(({ name, value }) => (
                  <Text key={name} letterSpacing="generous" size="xs">{`${capitalise(name)}: ${value}`}</Text>
                ))}
              </>
            )}
            {giftCardAttributes?.length > 0 &&
              giftCardAttributes?.map(({ key, value }) => <Text key={key} letterSpacing="generous" size="xs">{`${key} ${value}`}</Text>)}
          </VStack>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={3}>
          {isGiftcard || isGwp ? (
            <Box />
          ) : (
            <FormQuantity
              handleChange={handleQuantity}
              isDisabled={loading || isOutOfStock}
              max={item?.merchandise?.quantityAvailable}
              value={quantity}
            />
          )}
          {!isAutoAdd && (
            <FormButton
              isDisabled={loading}
              onClick={handleRemove}
              fontSize="xs"
              letterSpacing="ample"
              p={1}
              mb={-1.5}
              mr={-1}
              textTransform="none"
              title={cart?.additionalRemove || ""}
              aria-label={cart?.additionalRemove || ""}
              variant="ghost"
            >
              {cart?.additionalRemove}
            </FormButton>
          )}
        </Flex>
      </Flex>
    </HStack>
  )
})
