import React, { Fragment, memo, useMemo } from "react"
import { Divider, Flex } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { CartListItem } from "@app/components/Cart/List/CartListItem"
import { useConfigContext } from "@app/providers/config"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

export const CartList: React.FC = memo(() => {
  const {
    settings: {
      cart: { gwpAttribute },
    },
  } = useConfigContext()
  const { cart } = useCartContext()

  const items = useMemo(() => {
    const isGwp = (item: CartLine) => item?.attributes?.find(x => gwpAttribute && x.key === gwpAttribute)
    return cart?.lines?.sort((a, b) => (!isGwp(a) ? 1 : !isGwp(b) ? -1 : 0))
  }, [gwpAttribute, cart?.lines])

  const cartLength = items?.length || 0

  return cartLength > 0 ? (
    <Flex flexDirection="column" alignItems="stretch" justifyContent="flex-start" w="full">
      {items?.map(item => (
        <Fragment key={item?.id}>
          <CartListItem item={item} />
          <Divider borderBottomColor="grey.fog" mt={4} mb={4} />
        </Fragment>
      ))}
    </Flex>
  ) : null
})
