import { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { useFunctions } from "@app/hooks/useFunctions"
import { useStorage } from "@app/hooks/useStorage"

import type { Location } from "@root/types/global"
import { useAnalytics } from "./useAnalytics"

const INITIAL_STATE = { email: "", firstName: "", lastName: "" }

export const useNewsletter = (location: Location) => {
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)
  const { state, dispatch } = useAppContext()
  const [turnstileToken, setTurnstileToken] = useState("")
  const {
    settings: { keys, routes },
  } = useConfigContext()
  const { callFunction, errors, loading, clearErrors } = useFunctions()
  const { storage } = useStorage()
  const { trackUserIdentity } = useAnalytics()

  const { newsletter } = useStaticQuery<GatsbyTypes.StaticNewsletterQuery>(graphql`
    query StaticNewsletter {
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
        templateHomepage
        templateCollection
        templateProduct
        templateArticle
        additionalTitle
        additionalPlaceholder
        additionalSuccess
        popupTitle
        popupSubtitle
        popupButtonText
      }
    }
  `)

  const route =
    Object.entries(routes)?.find(
      ([route, url]) => !route?.toLowerCase()?.includes("page") && url && location?.pathname?.includes(url)
    )?.[0] ||
    (location?.pathname === routes.HOMEPAGE && "HOMEPAGE")

  const active =
    (newsletter?.enabled && route && newsletter[`template${route?.charAt(0)?.toUpperCase() + route?.slice(1)?.toLowerCase()}`]) || false

  const handleActiveSubscribe = useCallback(
    (state: boolean) => {
      dispatch({
        type: "subscribe",
        payload: state,
      })
    },
    [dispatch]
  )

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    },
    [setData]
  )

  const handleOpen = useCallback(() => {
    handleActiveSubscribe(true)
  }, [handleActiveSubscribe])

  const handleClose = useCallback(() => {
    handleActiveSubscribe(false)
  }, [handleActiveSubscribe])

  const handleSubmit = useCallback(
    async (event: React.BaseSyntheticEvent) => {
      event.preventDefault()
      const { status, body } = await callFunction("customer-subscribe", { ...data, ["cf-turnstile-token"]: turnstileToken })
      trackUserIdentity(data)
      setSuccess("success" === status)
      return { customer: body }
    },
    [callFunction, data, trackUserIdentity, turnstileToken]
  )

  const [checkboxes, setCheckboxes] = useState([
    { id: 1, title: "Sleepwear", value: "sleepwear", checked: false },
    { id: 2, title: "Bed Linen", value: "bedLinen", checked: false },
    { id: 3, title: "Home", value: "home", checked: false },
    { id: 4, title: "Loungewear", value: "Loungewear", checked: false },
    { id: 5, title: "Pet", value: "pet", checked: false },
    { id: 6, title: "All", value: "all", checked: false },
  ])

  const handleRegistrationSubmit = useCallback(
    async (e, data) => {
      e.preventDefault()

      const interests = checkboxes
        .map(value => {
          if (value.checked == true) {
            return value.id
          }
        })
        ?.filter(Boolean)

      const response = await callFunction("customer-emarsys-register", {
        ...data,
        interests,
        ["cf-turnstile-response"]: turnstileToken,
      })

      if (response.status === "success") {
        setSuccess(true)
        trackUserIdentity(data)
      }
    },
    [callFunction, checkboxes, trackUserIdentity, turnstileToken]
  )
  useEffect(() => {
    let timeout: any = null

    if (active && !storage.get(keys.newsletter) && !state.activeSubscribe) {
      timeout = setTimeout(
        () => {
          storage.set(keys.newsletter, true, newsletter?.expiry)
          handleActiveSubscribe(true)
        },
        (newsletter?.delay || 0) * 1000
      )
    }

    return () => timeout && clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    // reset form when changing page
    clearErrors()
    setData(INITIAL_STATE)
    setSuccess(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return {
    data,
    errors,
    loading,
    success,
    newsletter,
    handleOpen,
    handleClose,
    handleSubmit,
    handleChange,
    activeSubscribe: state.activeSubscribe,
    checkboxes,
    setCheckboxes,
    handleRegistrationSubmit,
    setTurnstileToken,
  }
}
