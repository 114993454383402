module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.17.0__encoding_f76cf411e0bad14a5999a754dfd8d971/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Fine-Day","short_name":"Fine-Day","description":"For Good Nights And Slow Mornings","background_color":"#B4B3B4","theme_color":"#FFFFFF","display":"minimal-ui","icon":"static/images/icon.png","icons":[{"src":"static/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f73122b854d60047419ca5bd381611c2"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.17.0__encod_43e55cb68aa1fd40b70cb62f67672c88/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.17.0__encoding@0.1.13_react-dom@18.2.0_react_1cbea82430efe3ee8515c707e97414f0/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
