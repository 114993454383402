import React, { useEffect, useMemo } from "react"
import { PageProps } from "gatsby"
import "@formatjs/intl-getcanonicallocales/polyfill"
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-pluralrules/polyfill"
import "@formatjs/intl-pluralrules/locale-data/en"
import "@formatjs/intl-numberformat/polyfill"
import "@formatjs/intl-numberformat/locale-data/en"

import { useShopify } from "@app/hooks/useShopify"
import { useAppContext } from "@app/providers/app"
import { useCartContext } from "@app/providers/cart"
import { useCustomerContext } from "@app/providers/customer"
import { useConfigContext } from "@app/providers/config"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useEmarsys } from "@app/hooks/useEmarsys"
import { useMaintenance } from "@app/hooks/useMaintenance"
import { usePrevious } from "@app/hooks/usePrevious"
import { useShopifyProduct } from "@app/hooks/useShopify"
import { useInsideDataUser } from "@app/hooks/useInsideData"

import type { CollectionCombinedProps } from "@root/types/global"
import { CartAbandonedWidget } from "@app/components/Cart/CartAbandonedWidget"

type Props = PageProps & {
  isCart: boolean
  isSearch: boolean
  name: string
}

export const withLayout =
  (Component: React.FC<Omit<Props, "name">>) =>
  ({ name = "Layout", location, children, data, path }: Props) => {
    const { dispatch, activeCollection, setActiveCollection } = useAppContext()
    const {
      settings: { routes },
    } = useConfigContext()
    const { cart } = useCartContext()
    const { customer } = useCustomerContext()
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { collectionNormaliser } = useShopify()
    const { trackPageView, tracked } = useAnalytics()
    const { emarsysLoaded, trackEmarsys } = useEmarsys()

    const prevCustomer = usePrevious(customer)
    const prevCart = usePrevious(cart)

    const item: CollectionCombinedProps = useMemo(() => collectionNormaliser(data?.collection), [data?.collection, collectionNormaliser])

    useInsideDataUser()

    useEffect(() => {
      if (tracked) {
        trackPageView()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracked])

    useEffect(() => {
      if (item?.id !== activeCollection?.id) setActiveCollection({ ...item })
    }, [activeCollection?.id, item, setActiveCollection])

    useEffect(() => {
      selectProduct(data?.product, location?.pathname)
    }, [location?.pathname, cart?.currencyCode, data?.product, selectProduct])

    // Intentionally only run on location pathname change
    useEffect(() => {
      trackPageView()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname])

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [dispatch, location?.pathname])

    // Intentionally only run on customer change
    useEffect(() => {
      const logout = !customer && prevCustomer

      if (emarsysLoaded && !logout) trackEmarsys(data)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    // Intentionally only run on cart change
    useEffect(() => {
      if (emarsysLoaded && prevCart?.lines && cart?.lines && JSON.stringify(cart?.lines) !== JSON.stringify(prevCart?.lines))
        trackEmarsys(data)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart?.lines])

    // Intentionally only run on path change
    useEffect(() => {
      const login = !customer && path === `${routes.DASHBOARD}/`

      if (emarsysLoaded && cart && !login) trackEmarsys(data)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    const isCart = useMemo(() => location?.pathname?.includes(routes.CART), [location?.pathname, routes.CART])
    const isSearch = useMemo(() => location?.pathname?.includes(routes.SEARCH), [location?.pathname, routes.SEARCH])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      authorised && (
        <Component data={data} isCart={isCart} isSearch={isSearch} location={location}>
          <CartAbandonedWidget cartPage={cart} />
          {children}
        </Component>
      )
    )
  }
