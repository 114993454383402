import React, { memo, useMemo } from "react"
import { Box, Flex } from "@chakra-ui/react"

import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"
import { GiftWithPurchaseProduct } from "./GiftWithPurchaseProduct"
import { useMedia } from "@app/hooks/useMedia"

import { GiftWithPurchaseSwiper } from "./GiftWithPurchaseSwiper"

type Props = {
  isCartPage?: boolean
}

export const GiftWithPurchase: React.FC<Props> = memo(({ isCartPage }) => {
  const { giftWithPurchaseEnabled, giftWithPurchaseProducts } = useGiftWithPurchase(true)
  const { isSmall } = useMedia()

  const isAutoAddProductIncluded = useMemo(() => !!giftWithPurchaseProducts?.find(p => p.autoAdd), [giftWithPurchaseProducts])

  return !isAutoAddProductIncluded && giftWithPurchaseEnabled ? (
    <Box
      id="cart-gwp"
      backgroundColor="grey.cloud"
      pt={{ base: "3.5", sm: isCartPage ? "9" : "4.5" }}
      pb={{ base: "4.5", sm: isCartPage ? "9" : "4.5" }}
      textAlign="center"
      mb={2}
      width="full"
    >
      <Box maxW="container.sm" mx="auto">
        <Box px={{ base: "27.5", sm: "31" }}>
          {!isSmall && giftWithPurchaseProducts.length > 1 ? (
            <GiftWithPurchaseSwiper products={giftWithPurchaseProducts} />
          ) : (
            <Flex gap={4.5} justifyContent="center">
              {giftWithPurchaseProducts.map((p: any, i: number) => (
                <Box key={`gwp-product-${i}`} width="33.33%">
                  <GiftWithPurchaseProduct product={p} />
                </Box>
              ))}
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  ) : null
})
