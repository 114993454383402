import React, { memo, useState, useEffect, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Text } from "@chakra-ui/react"
import Turnstile from "react-turnstile"
import { FormInput } from "@app/components/Form/FormInput"
import { Icon } from "@app/components/Icon"

import type { Location } from "@root/types/global"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

type Props = {
  location: Location
  data: {
    email: string
    firstName: string
    lastName: string
  }
  errors: string[]
  handleSubmit: (event: React.BaseSyntheticEvent) => void
  handleChange: (event: React.BaseSyntheticEvent) => void
  setTurnstileToken: (token: string) => void
  loading: boolean
  success: boolean
}

export const FooterSubscribeForm: React.FC<Props> = memo(
  ({ data, errors: formErrors, handleSubmit, handleChange, setTurnstileToken, loading, success }) => {
    const {
      services: { cloudflare },
    } = useConfigContext()

    const { newsletter } = useStaticQuery<GatsbyTypes.StaticFooterSubscribeFormQuery>(graphql`
      query StaticFooterSubscribeForm {
        newsletter: sanitySettingNewsletter {
          additionalPlaceholder
          additionalSubmit
          additionalSuccess
          additionalMessage: _rawAdditionalMessage(resolveReferences: { maxDepth: 2 })
        }
      }
    `)

    const [disabled, setDisabled] = useState(true)
    const [hadValue, setHadValue] = useState(false)
    const [widgetId, setWidgetId] = useState<string | null>(null)
    const [validationError, setValidationError] = useState<string | null>(null)
    const [turnstileError, setTurnstileError] = useState<string | null>(null)

    const handleInvalid = (event: React.FormEvent<HTMLInputElement>) => {
      event.preventDefault()
      const message = event.target.validationMessage
      setValidationError(message)
    }

    const errors = useMemo(() => {
      return [...formErrors, validationError, turnstileError].filter(Boolean)
    }, [formErrors, turnstileError, validationError]) as string[]
    useEffect(() => {
      if (data?.email) setHadValue(true)
    }, [data?.email])

    const {
      helpers: { sanityContent },
    } = useCore()

    useEffect(() => {
      if (formErrors?.length || turnstileError) {
        setDisabled(true)
        window?.turnstile?.reset(widgetId)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors, turnstileError])

    const message = useMemo(() => sanityContent(newsletter?.additionalMessage), [sanityContent, newsletter?.additionalMessage])

    return !success ? (
      <Box as="form" onSubmit={handleSubmit}>
        <FormLabel htmlFor="email" position="static" fontSize="sm" ml="0" color="grey.navigationText">
          Email Address
        </FormLabel>
        <FormInput
          autoComplete="email"
          name="email"
          type="email"
          background="transparent"
          fontSize="md"
          errors={errors}
          onInvalid={handleInvalid}
          onChange={handleChange}
          isRequired
          value={data?.email}
          errorAlignment="flex-start"
          errorIcon={false}
          variant="filled"
          rightAddon={
            <Button
              iconSpacing="0"
              isLoading={loading}
              rightIcon={<Icon ml="1.125" name="chevron-right" />}
              color="grey.darkest"
              size="sm"
              isDisabled={disabled}
              type="submit"
              title={newsletter?.additionalSubmit || ""}
              aria-label={newsletter?.additionalSubmit || ""}
              variant="primary"
            />
          }
        />

        {message && (
          <Text color="grey.navigationText" mt={1.5} size="xxs">
            {message}
          </Text>
        )}

        {cloudflare?.turnstile_key && hadValue ? (
          <Turnstile
            sitekey={cloudflare.turnstile_key}
            onVerify={token => {
              setTurnstileToken(token)
              setDisabled(false)
              setTurnstileError(null)
            }}
            onLoad={widgetId => {
              setWidgetId(widgetId)
              setDisabled(true)
            }}
            onExpire={() => {
              setDisabled(true)
              if (widgetId) window?.turnstile?.reset(widgetId)
            }}
            onError={error => {
              setDisabled(true)
              setTurnstileError(`Verification failed (${error}). Please refresh the page and try again.`)
            }}
          />
        ) : null}
      </Box>
    ) : (
      <Text color="brand.grey" size="xs">
        {newsletter?.additionalSuccess}
      </Text>
    )
  }
)
